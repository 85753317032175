/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import sysgroup from '@/components/common/SysGroup';
import sysgroup from "../../../components/common/SysGroup";
import rxboxs from '../../../components/common/rx-boxs';
// Vue.component(sysgroup.name, sysgroup);
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, getTextByJs } from "@/assets/app.js";
export default {
  name: "lcfRyrz",
  components: {
    LCFQheader,
    'rx-sysgroup': sysgroup,
    'rx-boxs': rxboxs
  },
  data() {
    return {
      showGroupDialog: false,
      allowSelect: true,
      nodeGroupVm: {},
      //------------------------------------
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员入职",
      checkbox: false,
      showDataRZ: false,
      showDataHTDQ: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      userID: "",
      solId: "",
      actDefId: "",
      data: {}
    };
  },
  mounted() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      if (busad != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/onboardingAppForm/onboardingAppForm/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          let ksSearch = res.data.ks;
          this.data = res.data;
          this.data.ks = "";
          this.data.rzsj = format(this.data.rzsj);
          this.data.htdqr = format(this.data.htdqr);
          // this.data.zzsj = formatHM(this.data.zzsj);
          let urlSearch = _baseUrl + "/mobile/oa/getGroupsSearch.do?Q_NAME__S_LK=" + ksSearch;
          this.$ajax.post(urlSearch).then(resSearch => {
            let ks = [{
              text: resSearch.data.rowList[0].name,
              id: resSearch.data.rowList[0].groupId
            }];
            this.data.ks = JSON.stringify(ks);
          });
        });
      }
    },
    //科室选择
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    //---------------------------------------------------------------------
    dayTime(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      return year + "-" + month + "-" + date;
    },
    onConfirmRZ(timestamp) {
      this.data.rzsj = this.dayTime(timestamp);
      this.showDataRZ = false;
    },
    onConfirmHTDQ(timestamp) {
      this.data.htdqr = this.dayTime(timestamp);
      this.showDataHTDQ = false;
    },
    datas() {
      var ksJson = JSON.parse(this.data.ks);
      console.log(ksJson);
      var gwJson = JSON.parse(this.data.gw);
      var zwJson = JSON.parse(this.data.zw);
      var jsonDataObj = {
        persid: "",
        xm: this.data.xm,
        ksId: ksJson[0].id,
        ks: ksJson[0].text,
        gwId: gwJson[0].id,
        gw: gwJson[0].text,
        rzsj: this.data.rzsj,
        htdqr: this.data.htdqr,
        zwId: zwJson[0].id,
        zw: zwJson[0].text
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("jsonData", jsonData);
      return params;
      // return jsonDataObj;
    },

    onSubmit() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      // var ksJson = JSON.parse(this.data.ks)
      // console.log(this.data.ks)
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};